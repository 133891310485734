import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { DownloadService } from '../../services/download/download.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { FormatNumberPipe } from "../../helpers/pipes/format-number/format-number.pipe";
import { ConvertSizePipe } from "../../helpers/pipes/convert-size/convert-size.pipe";
import { SharedService } from '../../services/shared/shared.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { ExportService } from '../../services/export/export.service';
import { ExportExcel } from '../../interfaces/Export';
import { utils, writeFileXLSX  } from 'xlsx';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MathAbsPipe } from "../../helpers/math-abs/math-abs.pipe";

type DetailDossierListKeys = "dossiers-en-cours" | "dossiers-hors-delai" | "dossiers-clos" | "saisines-prematurees" | "messages-non-lus" | "dossiers-liste" | "dossiers-message" | "dossiers-document"
type DetailPartenariatListKeys = "partenariat-liste" | "partenariat-message" | "partenariat-document"

const detailDossierList: Readonly<Record<DetailDossierListKeys, "suivi" | "messages" | "documents">> = 
{
  "dossiers-en-cours" : "suivi",
  "dossiers-hors-delai" : "suivi",
  "dossiers-clos" : "suivi",
  "saisines-prematurees" : "suivi",
  "messages-non-lus" : "messages",
  "dossiers-liste" : "suivi",
  "dossiers-message" : "messages",
  "dossiers-document" : "documents"
}

const detailPartenariatList: Readonly<Record<DetailPartenariatListKeys, "suivi" | "messages" | "documents">> = 
{
  "partenariat-liste": "suivi",
  "partenariat-message": "messages",
  "partenariat-document": "documents"
}
@Component({
    selector: 'app-table',
    standalone: true,
    templateUrl: './table.component.html',
    styleUrl: './table.component.css',
    imports: [CommonModule, MatTableModule, MatProgressSpinnerModule, MatSortModule, FormatNumberPipe, ConvertSizePipe, MathAbsPipe]
})
export class TableComponent<T>
{

  @Input() parent!:string
  @Input() data_header_table!:object
  @Input() data_body_table: Array<T> = []
  @ViewChild(MatSort) sort!: MatSort

  date:Date = new Date()
  ELEMENT_DATA:Array<T> = []
  ORIGINAL_DATA:Array<T> = []
  dataSource!:MatTableDataSource<T>
  displayedColumns!: string[]
  titleColumns!: string[]
  devise:string = "€"
  idService!:number
  idUser!:number

  arrayDetailDossierList:string[] = Object.keys(detailDossierList)
  arrayDetailPartenariatList:string[] = Object.keys(detailPartenariatList)
  detailList:string[] = this.arrayDetailDossierList.concat(this.arrayDetailPartenariatList)
  exclusion:string[] = ["telecharger","exporter",""]
  is_partenaire: boolean = this.sharedService.isPartenaire()

  centerElementList:string[] = ["telecharger","exporter"]


  constructor
  (
    private downloadService: DownloadService,
    private exportService: ExportService,
    private navigationService: NavigationService,
    private sharedService: SharedService,
    private router: Router
  ) 
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user
    
    const id_service = this.sharedService.getIdService()
    this.idService = id_service
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('data_header_table' in changes) 
    {
      this.initDataSource()
    }
    else if ('data_body_table' in changes) 
    {
      this.initDataSource()
    }
  }

  ngOnInit()
  {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.initDataSource()
    })
  }

  ngAfterViewInit() 
  {
    this.dataSource.sort = this.sort
  }

  initDataSource()
  {
    this.displayedColumns = Object.keys(this.data_header_table)
    this.titleColumns = Object.values(this.data_header_table)

    this.ELEMENT_DATA = this.data_body_table
    this.dataSource = new MatTableDataSource<T>(this.ELEMENT_DATA)
  }

  goToMessage()
  {
    this.navigationService.navigateByURL(`partenariat/suivi-partenariat`, [null])
  }

  download_facturation(num_facture:string, fileName:string, annee:number, id:number)
  {
    this.downloadService.downloadProcessFacturation(num_facture, fileName, `factures/annees/${annee}/service_${id}`, this.idUser, id)
  }

  download_dossier_document(ref:string, fileName:string, id:number=0)
  {
    this.downloadService.downloadProcess(ref, fileName, 'dossiers', this.idUser, id)
  }

  download_partenariat_document(fileName:string, id:number=0)
  {
    this.downloadService.downloadProcessPartenariat(this.idService, fileName, 'services', this.idUser, id)
  }

  markAsRead(id_document:number, id_user:number)
  {
    this.sharedService.markDocumentAsRead(id_document, id_user)
    this.dataSource.data.map((document:any) => {
      if(document.id_document == id_document)
      {
        document.non_lu = false
      }
    })
  }

  markDocPartenariatAsRead(id_document:number, id_user:number)
  {
    this.sharedService.markDocumentPartenariatAsRead(id_document, id_user)
    this.dataSource.data.map((document:any) => {
      if(document.id_document == id_document)
      {
        document.non_lu = false
      }
    })
  }

  export(ref:string)
  {
    this.sharedService.blurPage()

    this.exportService.export(ref, this.idService).subscribe((data:ExportExcel[]) => {

      // console.log(data)

      // const ws = utils.json_to_sheet(data)

      // const workbook = utils.book_new()

      // utils.book_append_sheet(workbook, ws, "Worksheet")

      // const date_string = this.date.toLocaleString()
      //   .replaceAll("/","_")
      //   .replaceAll(":","_")
      //   .replaceAll(" ","_")

      // writeFileXLSX(workbook, `mde_export_details_facture_${date_string}.xlsx`, { compression: true })

      // return

      const mapData = data.map(x => {
        const price = formatPrice(x["montant_ht"], x["tva"])

        const array = []
        if( price.length > 1 )
        {
          for (let i = 0; i < price.length; i++) 
          {
            let d =
            {
              "Dossier":x["ref"],
              "Abonné":x["abonne"],
              "CP":x["cp"],
              "Commune":x["commune"],
              "N° contrat":x["num_contrat"],
              "Prestations":price.length > 1 ? x["intitule"].split(/\n|\\n/)[i] : x["intitule"],
              "Mt. HT":price.length > 1 ? price[i]['HT'] : x['total'],
              "Mt. TTC":price.length > 1 ? price[i]['TTC'] : x['total'] + x['total'] * x["tva"] / 100
            } 
            array.push(d)
          }
        }
        else
        {
          let d =
          {
            "Dossier":x["ref"],
            "Abonné":x["abonne"],
            "CP":x["cp"],
            "Commune":x["commune"],
            "N° contrat":x["num_contrat"],
            "Prestations":price.length > 1 ? x["intitule"].split(/\n|\\n/)[0] : x["intitule"],
            "Mt. HT":price.length > 1 ? price[0]['HT'] : x['total'],
            "Mt. TTC":price.length > 1 ? price[0]['TTC'] : x['total'] + x['total'] * x["tva"] / 100
          } 
          array.push(d)
        }
        return array
      })

      const filteredData = flattenArray(mapData)
      
      const ws = utils.json_to_sheet(filteredData)

      const wscols = 
      [
        {wch:10},
        {wch:40},
        {wch:6},
        {wch:40},
        {wch:20},
        {wch:50},
        {wch:6},
        {wch:6}
      ]
    
      ws['!cols'] = wscols

      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, ws, "Worksheet")

      const date_string = this.date.toLocaleString()
        .replaceAll("/","_")
        .replaceAll(":","_")
        .replaceAll(" ","_")

      writeFileXLSX(workbook, `mde_export_details_facture_${date_string}.xlsx`, { compression: true })

      this.sharedService.focusPage()

      this.sharedService.postTableConnexion(this.idUser, 234, this.idUser).subscribe()
    })
    
  }

  goToDossierDetails(ref:string, str:string, id_dossier:number|null=null)
  {
    if(!ref) return

    const onglet = detailDossierList[(str as DetailDossierListKeys)]

    this.navigationService.navigateTo(`dossiers/details/ref_${ref}/id_dossier_${id_dossier}/onglet_${onglet}`, [null])
  }

  goToPartenariatDetails(ref:string, str:string, id_dip:number|null=null)
  {
    if(!ref) return

    const onglet = detailPartenariatList[(str as DetailPartenariatListKeys)]

    this.navigationService.navigateTo(`partenariat/details/ref_${ref}/id_dip_${id_dip}/onglet_${onglet}`, [null])
  }
}

function flattenArray(arr:any[]) 
{
  return arr.reduce((acc, curr) => {
      if (Array.isArray(curr)) 
      {
        acc.push(...flattenArray(curr))
      } 
      else 
      {
        acc.push(curr)
      }
      return acc
  }, [])
}

function formatPrice(montant:string, tva:number)
{
  const retour:any = []

  const pushToArray = (mt:string) => 
  {
    retour.push({HT:Number(mt) * 1, TTC:Number(mt) * 1 + (Number(mt) * tva/100)})
  }

  if(montant)
  {
    if (montant.includes('\n') || montant.includes('\\n')) 
    {
      const montants = montant.split(/\n|\\n/)
      montants.forEach(mt => {
          pushToArray(mt.trim())
      })
    }
    else
    {
      pushToArray(montant)
    }
  }
  return retour
}
