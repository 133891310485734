<main class="main table">
    <app-breadcrumb [title]="title"></app-breadcrumb>

    <h3 class="grey-dark">{{ title }}</h3>

    <div>      
      <span class="small bold bg-blue-light-2 blue-dark-2 py-1 ps-2">
        Les données enregistrées ne sont plus modificables. En cas d'erreur de saisie, contactez votre interlocuteur
      </span>
      <a class="small bold bg-blue-light-2 blue underline py-1 pe-2 cursor" (click)="goToContactPage()"> via ce lien</a>
  
      <span>
        <app-searchbar (onSearch)="getDataDonneesAbonnes($event)"></app-searchbar>
      </span>
    </div>

    <div class="mat-elevation-z0 mt-4">
      <table mat-table [dataSource]="dataSource" matSort [formGroup]="form">
    
        <ng-container matColumnDef="annee">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Année</th>
          <td mat-cell *matCellDef="let element; let i = index" class="annee">
            {{element.annee}}
          </td>
        </ng-container>

        <ng-container matColumnDef="abonnes_eau_potable">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Abonnés eau potable</th>
          <td mat-cell *matCellDef="let element; let i = index" class="abonnes_eau_potable int-column"> 

            @if (i === 0 && editMode) 
            {
              <input [ngClass]="switchToTabletMode() ? '': 'form-control'" type="text" (keydown)="preventTextInput($event)" formControlName="abonnes_eau_potable" size="6" min="0" maxlength="7">
            }
            @else
            {
                {{element.abonnes_eau_potable | formatNumber }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="abonnes_eau_brute">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Abonnés eau brute</th>
          <td mat-cell *matCellDef="let element; let i = index" class="abonnes_eau_brute int-column"> 
            @if (i === 0 && editMode) 
            {
              <input [ngClass]="switchToTabletMode() ? '': 'form-control'" type="text" (keydown)="preventTextInput($event)" formControlName="abonnes_eau_brute" size="6" min="0" maxlength="7">
            }
            @else
            {
                {{element.abonnes_eau_brute | formatNumber}}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="abonnes_assainissement_collectif">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="text-start">Abonnés assainissement collectif</span> </th>
          <td mat-cell *matCellDef="let element; let i = index" class="abonnes_assainissement_collectif int-column"> 
            @if (i === 0 && editMode) 
            {
              <input [ngClass]="switchToTabletMode() ? '': 'form-control'" type="text" (keydown)="preventTextInput($event)" formControlName="abonnes_assainissement_collectif" size="6" min="0" maxlength="7">
            }
            @else
            {
                {{element.abonnes_assainissement_collectif | formatNumber}}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="abonnes_assainissement_non_collectif">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="text-start">Abonnés assainissement non collectif</span></th>
          <td mat-cell *matCellDef="let element; let i = index" class="abonnes_assainissement_non_collectif int-column"> 
            @if (i === 0 && editMode) 
            {
              <input [ngClass]="switchToTabletMode() ? '': 'form-control'" type="text" (keydown)="preventTextInput($event)" formControlName="abonnes_assainissement_non_collectif" size="6" min="0" maxlength="7">
            }
            @else
            {
                {{element.abonnes_assainissement_non_collectif | formatNumber}}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="total_abonnes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total abonnés</th>
          <td mat-cell *matCellDef="let element; let i = index" class="total_abonnes int-column">

            <span [ngClass]="i === 0 && editMode ? 'dark': ''">
              @if(i === 0)
              { 
                {{total_abonnes | formatNumber}}
              } 
              @else
              {
                {{element.total_abonnes | formatNumber}}
              }
            </span>
 
          </td>
        </ng-container>

        <ng-container matColumnDef="date_effet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date d'effet</th>
          <td mat-cell *matCellDef="let element; let i = index" class="date_effet"> 
            @if (i === 0 && editMode) 
            {
              <input type="date" formControlName="date_effet" size="">
            }
            @else
            {
              {{ element.date_effet ? (element.date_effet | date:'dd-MM-yyyy') : '-'}}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="raison_maj">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Raison de mise à jour</th>
          <td mat-cell *matCellDef="let element; let i = index" class="raison_maj" [ngClass]="i === 0 && editMode ? 'highlight': ''">
            @if (i === 0 && editMode) 
            {
              <div>
                <select class="select" formControlName="raison_maj" (change)="changeRaisonMaj($event)">>
                  @for (option of selectOptions; track $index) 
                  {
                    <option value={{option.id_raison_maj}} [ngClass]="option.id_raison_maj === 5 ? 'selected': ''">{{option.description}}</option>
                  }
                </select>
              </div>
              <div>
                <input type="file" hidden (change)="onFileSelected($event)" #fileUpload accept="{{ allowedTypes.toString() }}" id="inputFileHidden">
                <div class="d-flex justify-content-between align-items-center mt-1" *ngIf="form.get('raison_maj')?.value == 3">
                  <small class="dark bold align-self-center" id="fileName">{{ fileName }}</small>
                  <button class="no-border bg-blue white px-3 py-1" (click)="fileUpload.click()">Parcourir...</button>
                </div>
                <button class="no-border bg-green white px-3 py-1 mt-2 float-end" (click)="postData(element.id_abonnement_eau)">Valider</button>
              </div>
            }
            @else
            {
              {{element.raison_maj}}
            }
          </td>
        </ng-container>

        
        <ng-container matColumnDef="empty" sticky="true">
          <th mat-header-cell *matHeaderCellDef [hidden]="!isColumnVisible"></th>
          <td mat-cell *matCellDef="let element; let i = index" class="empty" [hidden]="!isColumnVisible">
              @if ((!editMode && element.need_edit && !element.validation) ) 
              {
                <img class="table-icons cursor" src='/assets/icons/picto-modifier.png' (click)="editRow()"> 
              }
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table header-table-bold text-start"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" class="content-table" 
          [ngClass]="
          [
            row.need_edit && !row.validation ? 'red' : '',
            canEditAbo() ? 'bold' : ''
          ]"
          (click)="switchToTabletMode() ? editRow(): ''"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <h3 class="orange d-flex justify-content-center mt-5">Aucun résultat pour cette recherche</h3>
          </td>
        </tr>

      </table>
    </div>
</main>
  