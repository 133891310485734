import { Routes } from '@angular/router';
import { HomeComponent } from './content/home/home.component';
import { InterlocuteursComponent } from './content/interlocuteurs/interlocuteurs.component';
import { FacturationComponent } from './content/facturation/facturation.component';
import { DonneesAbonnesComponent } from './content/donnees-abonnes/donnees-abonnes.component';
import { ProfilComponent } from './content/profil/profil.component';
import { ListeActionsComponent } from './content/liste-actions/liste-actions.component';
import { DossiersListeComponent } from './content/dossiers/list/dossiers-liste.component';
import { DossiersDetailsComponent } from './content/dossiers/details/dossier-details.component';
import { PartenariatListeComponent } from './content/partenariat/list/partenariat-liste/partenariat-liste.component';
import { PartenariatDetailsComponent } from './content/partenariat/details/partenariat-details.component';
import { DossiersDocumentsComponent } from './content/dossiers/dossiers-documents/dossiers-documents.component';
import { DossiersMessagesComponent } from './content/dossiers/dossiers-messages/dossiers-messages.component';
import { PartenariatDocumentsComponent } from './content/partenariat/partenariat-documents/partenariat-documents.component';
import { PartenariatMessagesComponent } from './content/partenariat/partenariat-messages/partenariat-messages.component';
import { ConnexionComponent } from './connexion/connexion.component';
import { AppContainerComponent } from './app-container/app-container.component';
import { AuthGuard } from './services/route-guard/route-guard.service';
import { NewPassword } from './pages/new-password.component';
import { DossierGuard } from './services/dossier-guard/dossier-guard.service';
import { TransmettreComponent } from './pages/transmettre.component';
import { PartenariatGuard } from './services/partenariat-guard/partenariat-guard.service';
import { DossiersClosComponent } from './content/tableau-de-bord/dossiers-clos/dossiers-clos.component';
import { DossiersEnCoursComponent } from './content/tableau-de-bord/dossiers-en-cours/dossiers-en-cours.component';
import { DossiersHorsDelaiComponent } from './content/tableau-de-bord/dossiers-hors-delai/dossiers-hors-delai.component';
import { SaisinesPrematureesComponent } from './content/tableau-de-bord/saisines-prematurees/saisines-prematurees.component';
import { MessagesNonLusComponent } from './content/tableau-de-bord/messages-non-lus/messages-non-lus.component';
import { AutologinComponent } from './autologin/autologin.component';


export const routes: Routes = 
[
    {
        path: 'connexion',
        component: ConnexionComponent
    },
    {
        path: 'autologin',
        component: AutologinComponent
    },
    {
        path: 'mot-de-passe',
        component: NewPassword
    },
    {
        path: '',
        component: AppContainerComponent,
        canActivate:[AuthGuard],
        canActivateChild:[AuthGuard],
        children:
        [
            {
                path: '',
                component: HomeComponent,
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/dossiers-clos',
                component: DossiersClosComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/dossiers-en-cours',
                component: DossiersEnCoursComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/dossiers-hors-delai',
                component: DossiersHorsDelaiComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/saisines-prematurees',
                component: SaisinesPrematureesComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'tableau-de-bord/messages-non-lus',
                component: MessagesNonLusComponent
                //canActivate: [DossierGuard]
            },
            {
                path: 'dossiers',
                component: DossiersListeComponent,
                //canActivate: [DossierGuard]
            },
            {
                path: 'dossiers/documents',
                component: DossiersDocumentsComponent,
                //canActivate: [DossierGuard]
            },
            {
                path: 'dossiers/messages',
                component: DossiersMessagesComponent,
                //canActivate: [DossierGuard]
            },
            {
                path: 'dossiers/details/:ref/:id_dossier/:onglet',
                component: DossiersDetailsComponent,
                //canActivate: [DossierGuard]
            },
            {
                path: 'partenariat',
                component: PartenariatListeComponent
            },
            {
                path: 'partenariat/documents',
                component: PartenariatDocumentsComponent,
                canActivate: [PartenariatGuard]
            },
            {
                path: 'partenariat/messages',
                component: PartenariatMessagesComponent,
                canActivate: [PartenariatGuard]
            },
            {
                path: 'partenariat/details/:ref/:id_dip/:onglet',
                component: PartenariatDetailsComponent,
                canActivate: [PartenariatGuard]
            },
            {
                path: 'partenariat/suivi-partenariat',
                component: TransmettreComponent
            },
            {
                path: 'interlocuteurs',
                component: InterlocuteursComponent
            },
            {
                path: 'donnees-abonnes',
                component: DonneesAbonnesComponent
            },
            {
                path: 'facturation',
                component: FacturationComponent
            },
            {
                path: 'profil',
                component: ProfilComponent
            },
            {
                path: 'liste-actions',
                component: ListeActionsComponent
            }
        ]
    }

]

